import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/cjs/Row";
import Image from "react-bootstrap/cjs/Image";
import reportWebVitals from './reportWebVitals';
import axios from 'axios'

class ActivationForm extends React.Component {

    constructor( props ) {
        super( props );
    }

    activate() {
        this.props.activate();
    }

    render() {
        if ( !this.props.activated ) {
            return <Row>
                <form className="col-12">
                    <h2>Активация пакета</h2>
                    {this.props.data.formErrors && this.props.data.formErrors[0] &&
                    <h3 className="bg-warning p-3">{this.props.data.formErrors[0].defaultMessage}</h3>}
                    <div className="form-group invisible">
                        <label htmlFor="email">Емейл адрес</label>
                        <input onChange={event => this.props.onchange( event )}
                               value={this.props.email}
                               type="email"
                               className="form-control"
                               id="email" name="email" aria-describedby="emailHelp"
                               placeholder="email@domain.az"/>
                        <small id="emailHelp" className="form-text text-muted">Мы отправим на него ключи из пакета.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="card">Код активации</label>
                        <input onChange={event => this.props.onchange( event )} type="text" className="form-control" id="card" name="card"
                               placeholder="11111-11111-11111-11111-11111-11"/>
                        <small id="cardHelp" className="form-text text-muted">Можно вводить только цифры, без минусов.</small>
                        {this.props.data.fieldErrors && this.props.data.fieldErrors['card'] &&
                        <small className="form-text text-danger">{this.props.data.fieldErrors['card'].defaultError}</small>}
                    </div>
                    <div className="form-group flex-row">
                        <button onClick={() => this.activate()} type="button" className="btn btn-outline-success float-right ">Активировать</button>
                    </div>
                </form>
            </Row>
        }
        else {
            const fi = this.props.data.transactionInfo.soldItems[0]
            const ti = this.props.data.transactionInfo
            let id = '';
            for ( const soldItem of ti.soldItems ) {
                id = <>{id}
                    <div className="item-info">
                        <h4>{soldItem.description}</h4>
                        <label>{soldItem.codeLabel} <strong>{soldItem.token}</strong></label>
                        <p className="item-instructions">
                            <div dangerouslySetInnerHTML={{__html: soldItem.infoText}}/>
                        </p>
                    </div>
                </>
            }
            return <Row>
                <div className="col-12">
                    <h2>Активация успешна</h2>
                    <h3>{fi.headDescription}</h3>
                    {id}
                </div>
                <div className="col-12">
                    <button onClick={window.print} className="btn btn-outline-success float-right">Распечатать</button>
                </div>
            </Row>
        }
    }
}

class Activation extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {token: props.token, username: props.username, activated: false, email: 'asd@e-vision.by', card: '', data: {}, view: ''};
        this.onchange = this.onchange.bind( this );
        this.activate = this.activate.bind( this );
    }

    onchange( event ) {
        this.setState( {[event.target.name]: event.target.value} )
    }

    activate() {
        const params = new URLSearchParams();
        params.append( 'email', this.state.email )
        params.append( 'card', this.state.card )
        params.append( 'staffId', this.state.username )
        params.append( 'token', this.state.token )
        axios.post( '/api/esd/sb/activation', params )
            .then( value => {
                if ( value.status === 200 ) {
                    this.setState( {view: value.data.view, data: value.data.data, activated: value.data.data.activated} )
                }
                console.log( value )
            } )
            .catch( reason => {
                console.log( reason )
            } );
    }

    render() {
        return (
            <Container>
                <ActivationHeader/>
                <ActivationForm email={this.state.email} card={this.state.card} data={this.state.data} activated={this.state.data.activated}
                                onchange={this.onchange} activate={this.activate}/>
                <ActivationFooter/>
            </Container>
        )
    }
}


function ActivationHeader() {
    return <Row className="header align-content-center mt-4">
        <div className="col-lg-3">
            <Image className="img-fluid float-left" src="maxi-asbis-az.png"/>
        </div>
        <div className="offset-lg-6 col-lg-3">

        </div>
    </Row>
}

function ActivationFooter() {
    return <Row className="footer mt-5 text-muted">
        <div className="col-lg-4">
            &copy; 2023 ASBIS
        </div>
        <div className="offset-lg-2 col-lg-6">
            <div className="text-muted float-right">В случае проблем с активацией кода обращайтесь:
                <div className=""><a className="float-right" href="mailto:azkeys@asbis.biz">Емейл: azkeys@asbis.biz</a></div>
            </div>
        </div>
    </Row>
}


class LoginForm extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {loggedIn: false, token: '', loginMessage: '', username: '', password: ''}
    }

    login() {
        const params = new URLSearchParams();
        params.append( 'username', this.state.username )
        params.append( 'password', this.state.password )
        axios.post( '/api/esd/sb/login', params )
            .then( value => {
                if ( value.status === 200 ) {
                    this.setState( {loggedIn: value.data.loggedIn, token: value.data.token, loginMessage: value.data.loginMessage} )
                }
                console.log( value )
            } )
            .catch( reason => {
                console.log( reason )
                this.setState( {loggedIn: false, token: '', loginMessage: '' + reason} )
            } );
    }

    onchange( event ) {
        this.setState( {[event.target.name]: event.target.value} )
    }

    render() {
        if ( this.state.loggedIn ) {
            return <Activation token={this.state.token} username={this.state.username}/>
        }
        else {
            return <Container fluid="sm">
                <ActivationHeader/>
                <Row>
                    <form className="col-12">
                        <h2>Логин</h2>
                        <div className="form-group">
                            <label htmlFor="email">Пользователь</label>
                            <input onChange={event => this.onchange( event )}
                                   value={this.state.username}
                                   type="email"
                                   className="form-control"
                                   id="username" name="username"
                                   aria-describedby="emailHelp"
                                   placeholder="Имя пользователя"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="card">Пароль</label>
                            <input onChange={event => this.onchange( event )}
                                   type="password" className="form-control"
                                   id="password" name="password"
                                   placeholder=""/>
                            <small className="form-text text-danger">{this.state.loginMessage}</small>
                        </div>
                        <div className="form-group flex-row">
                            <button onClick={() => this.login()} type="button" className="btn btn-outline-success float-right ">Войти</button>
                        </div>
                    </form>
                </Row>
                <ActivationFooter/>
            </Container>
        }
    }
}


ReactDOM.render( <LoginForm/>, document.getElementById( 'root' ) );

reportWebVitals();
